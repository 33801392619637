import {
    ProductSubscriberCallback,
    ShopCart,
    ShopCartTransaction,
    UpdateType
} from "@/lib/cart/cart-types";
import { create } from "zustand";

interface CartStore {
    cart: ShopCart | undefined;
    subscribers: Map<number, Set<ProductSubscriberCallback>>;
    updateCartItem: (
        // eslint-disable-next-line no-unused-vars
        productID: number,
        // eslint-disable-next-line no-unused-vars
        updateType: UpdateType,
        // eslint-disable-next-line no-unused-vars
        promotionID: number | null,
        // eslint-disable-next-line no-unused-vars
        newUnits?: number
    ) => void;
    // eslint-disable-next-line no-unused-vars
    changePromotion: (cartTransactionID: number, promotionID: number | null) => void;
    // eslint-disable-next-line no-unused-vars
    subscribeToProduct: (productID: number, callback: ProductSubscriberCallback) => () => void;
    // eslint-disable-next-line no-unused-vars
    initialize: (initialCart: ShopCart | undefined) => void;
}

export const useCartStore = create<CartStore>()((set, get) => ({
    cart: undefined,
    subscribers: new Map(),

    initialize: (initialCart) => {
        set({ cart: initialCart });

        // If there's an initial cart, notify all subscribers of their relevant transactions
        if (initialCart) {
            // console.log("Initial cart set, notifying subscribers...");

            // TODO: clear previous values and add new values in one step not two
            // TODO: unify all subscriber updates to one function
            // clear previous values
            get().subscribers.forEach((subscribers) => {
                subscribers.forEach((callback) => callback(undefined));
            });

            // Get all unique product IDs from the cart
            const productIDs = new Set(initialCart.transactions.map((t) => t.productID));

            // For each product ID, notify its subscribers if there is a transaction for that product
            productIDs.forEach((productID) => {
                const subscribers = get().subscribers.get(productID);
                if (subscribers) {
                    const transaction = initialCart.transactions.filter(
                        (t) => t.productID === productID
                    );
                    subscribers.forEach((callback) => callback(transaction));
                }
            });
        } else {
            // If initialCart is undefined, notify all subscribers with undefined
            get().subscribers.forEach((subscribers) => {
                subscribers.forEach((callback) => callback(undefined));
            });
        }
    },

    updateCartItem: (productID, updateType, promotionID, newUnits?) => {
        const currentCart = get().cart || createEmptyCart();
        const updatedTransactions = currentCart.transactions
            .map((trans) =>
                trans.productID === productID && trans.manualPromotionID === promotionID
                    ? updateTransaction(trans, updateType, newUnits)
                    : trans
            )
            .filter(Boolean) as ShopCartTransaction[];

        const newCart = {
            ...currentCart,
            ...updateCartTotals(updatedTransactions),
            transactions: updatedTransactions
        };

        set({ cart: newCart });

        // Notify subscribers
        const subscribers = get().subscribers.get(productID);
        if (subscribers) {
            const transactions = newCart.transactions.filter((t) => t.productID === productID);
            subscribers.forEach((callback) => callback(transactions));
        }
    },

    changePromotion: async (cartTransactionsID: number, promotionID: number | null) => {
        const currentCart = get().cart || createEmptyCart();

        const currentTrans = currentCart.transactions.find(
            (item) => item.cartTransactionID === cartTransactionsID
        );

        if (!currentTrans) {
            return;
        }

        currentTrans.manualPromotionID = promotionID;
        currentTrans.extPrice = Number(currentTrans.numUnits) * Number(currentTrans.unitPrice);

        const updatedTransactions = currentCart.transactions.map((item) =>
            item.cartTransactionID === cartTransactionsID ? currentTrans : item
        );

        const newCart = {
            ...currentCart,
            ...updateCartTotals(updatedTransactions),
            transactions: updatedTransactions
        };

        set({ cart: newCart });

        // Notify subscribers
        const subscribers = get().subscribers.get(currentTrans.productID);
        if (subscribers) {
            const transactions = newCart.transactions.filter(
                (t) => t.productID === currentTrans.productID
            );
            subscribers.forEach((callback) => callback(transactions));
        }
    },

    subscribeToProduct: (productID, callback) => {
        const store = get();

        if (!store.subscribers.has(productID)) {
            store.subscribers.set(productID, new Set());
        }
        const productSubscribers = store.subscribers.get(productID)!;

        productSubscribers.add(callback);

        const transactionsArr = store.cart?.transactions.filter((t) => t.productID === productID);
        callback(transactionsArr);

        // Return cleanup function
        return () => {
            const currentStore = get();
            const currentProductSubscribers = currentStore.subscribers.get(productID);
            if (currentProductSubscribers) {
                currentProductSubscribers.delete(callback);
                if (currentProductSubscribers.size === 0) {
                    currentStore.subscribers.delete(productID);
                }
            }
        };
    }
}));

function createEmptyCart(): ShopCart {
    return {
        cartID: null,
        customerID: 0,
        status: "InProgress",
        totalNumUnits: 0,
        totalCases: 0,
        totalFullPrice: 0,
        totalDiscount: 0,
        totalExtPrice: 0,
        pricingLastUpdated: new Date("1/1/1970"),
        transactions: []
    };
}

function updateTransaction(
    cartTransaction: ShopCartTransaction,
    updateType: UpdateType,
    newUnits?: number
): ShopCartTransaction | null {
    if (updateType === "delete") return null;

    let newQuantity = cartTransaction.numUnits;

    switch (updateType) {
        case "plus-unit":
            newQuantity = cartTransaction.numUnits + 1;
            break;
        case "plus-case":
            newQuantity = cartTransaction.numUnits + cartTransaction.wholesaleUnitsPerCase;
            break;
        case "minus-unit":
            newQuantity = cartTransaction.numUnits - 1;
            break;
        case "minus-case":
            newQuantity = cartTransaction.numUnits - cartTransaction.wholesaleUnitsPerCase;
            break;
        case "set-units":
            if (newUnits !== undefined && !isNaN(newUnits) && newUnits >= 0) {
                newQuantity = newUnits;
            } else {
                console.error(`update type set-units called without a newUnit value, ${newUnits}`);
            }
        default:
            break;
    }

    if (newQuantity === 0) return null;

    return {
        ...cartTransaction,
        numUnits: newQuantity,
        extPrice: newQuantity * cartTransaction.unitPrice
    };
}

function updateCartTotals(
    transactions: ShopCartTransaction[]
): Pick<
    ShopCart,
    "totalNumUnits" | "totalCases" | "totalFullPrice" | "totalDiscount" | "totalExtPrice"
> {
    const totalNumUnits = transactions.reduce((sum, item) => sum + item.numUnits, 0);
    const totalCases = transactions.reduce(
        (sum, item) => sum + Math.floor(item.numUnits / item.wholesaleUnitsPerCase),
        0
    );
    const totalDiscount = transactions.reduce(
        (sum, item) => sum + item.discount * item.numUnits,
        0
    );
    const totalExtPrice = transactions.reduce((sum, item) => sum + Number(item.extPrice), 0);
    const totalFullPrice = transactions.reduce(
        (sum, item) =>
            sum + (Number(item.unitPrice) + Number(item.discount)) * Number(item.numUnits),
        0
    );

    return {
        totalNumUnits,
        totalCases,
        totalExtPrice,
        totalDiscount,
        totalFullPrice
    };
}
